<template>
  <div class="d-flex justify-content-around bg-light rounded shadow-sm align-items-stretch pb-3">
    <div class="d-flex gap-3 flex-fill h-100">
      <img class="mt-0 mb-auto" src="@/assets/images/icon-ticket.svg" alt="Ticket" />
      <div>
        <strong>{{ $t('price_per_person') }}</strong>
        <div class="text-primary fw-bold">{{ price }}</div>
        <div class="text-primary">{{ priceChildren }}</div>
      </div>
    </div>

    <div class="d-flex gap-3 flex-fill h-100">
      <img class="mt-0 mb-auto" src="@/assets/images/icon-clock.svg" alt="Clock" />
      <div>
        <strong>{{ $t('duration') }}</strong>
        <div class="text-primary fw-bold">{{ duration }}</div>
      </div>
    </div>
  </div>

  <div class="pt-4 pb-4">
    <Calendar
      :highlightedDates="highlightedDates"
      :dateAndTime="dateAndTime"
      :currentDate="currentDate"
      :doubleCol="false"
      @selectedDate="handleDateSelection"
    />

    <div class="d-flex justify-content-center border-top border-bottom py-3 my-3">
      <span
        class="fw-bold"
        v-if="hasSelectedDateAndTime"
      >
        {{ formatDisplayDate(selectedDate) }} - {{ parseTimeslot(selectedTime)?.time }}
      </span>
    </div>

    <Timeslots
      v-if="selectedDate"
      :chosenDate="dateAndTime?.[formatDate(selectedDate)]"
      :currentTime="selectedTime"
      :currentLanguage="selectedLanguage"
      @selectedTime="chosenTime"
    />
  </div>

  <div class="d-flex align-items-center flex-column">
    <template v-if="!isSelectedDateWaitingList">
      <button
        class="book-now btn btn-primary w-100"
        :disabled="!hasSelectedDateAndTime"
        @click="handleBookNow"
      >
        {{ $t('book_now') }}
      </button>
      <span class="text-muted fs-7 mt-2">
        {{ $t('continue_and_select_participants') }}
      </span>
    </template>

    <template v-else>
      <button
        rel="nofollow noreferrer"
        class="book-now btn btn-primary w-100"
        :disabled="!hasSelectedDateAndTime"
        @click="handleBookWaitingList"
      >
        {{ $t('get_on_waiting_list') }}
      </button>
      <span class="text-muted fs-7 mt-2">
        {{ $t('no_spots_available') }}
      </span>
    </template>
  </div>

  <div class="d-flex mt-4 gap-2" v-if="props.cityPageOfProduct">
    <img src="@/assets/images/icon-bike-and-key.svg" alt="Bike" />
    <div class="text-wrap mt-auto mb-auto">
      <span>{{ $t('proposition_alternative') }}&nbsp;</span>

      <span
        v-if="props.rentalProductOfCity && props.productsOfCity.length > 1"
        v-html="$t('proposition_alternative_link', {
          value: `<a href='${props.rentalProductOfCity.url}'>${props.rentalProductOfCity.title}</a>`,
          value2: `<a href='${props.cityPageOfProductUrl}'>${props.cityName}</a>`,
          html: true
        })"
      />
      <span
        v-else-if="props.rentalProductOfCity"
        v-html="$t('choose_more_tours', {
          value: `<a href='${props.rentalProductOfCity.url}'>${props.rentalProductOfCity.title}</a>`,
          html: true
        })"
      />
      <span
        v-else
        v-html="$t('view_other_tours_in_city', {
          value: `<a href='${props.cityPageOfProductUrl}'>${props.cityName}</a>`,
          html: true
        })"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useLocaleStore } from '@/stores/localeStore';
import { useProductAvailabilities } from '@/composables/useProductAvailabilities';
import { useDates } from '@/composables/useDates';
import { parseTimeslot } from '@/helpers/parseTimeslot';

import Calendar from "@/components/Calendar/Calendar.vue";
import Timeslots from "@/components/Calendar/Timeslots.vue";

const props = defineProps<{
  postId: number;
  productId: string;
  price: string;
  priceChildren: string;
  duration: string;
  cityPageOfProduct: any;
  cityPageOfProductUrl: string;
  cityName: string;
  productsOfCity: any;
  rentalProductOfCity: any;
}>();

const localeStore = useLocaleStore();

const today = new Date();

const { availabilities, loading, error, fetchAvailabilities } = useProductAvailabilities();

onMounted(async () => {
  await fetchAvailabilities(props.productId);
  createArrayOfDates();
});

// Calendar
const currentDate               = ref(today);
const selectedDate              = ref<Date | null>(null);
const selectedLanguage          = ref(localeStore.locale);
const selectedTime              = ref("");
const hasSelectedDateAndTime    = ref(false);
const isSelectedDateWaitingList = ref(false);
const highlightedDates          = ref<unknown[]>([]);
const dateAndTime               = ref<Record<string, Record<string, string[]>> | null>(null);

const createArrayOfDates = () => {
  const uniqueDays = new Set();
  const availabilitiesWithTimes: Record<string, Record<string, string[]>> = {};

  for (const availability of availabilities.value) {
    for (const date of availability.dates) {
      uniqueDays.add(date.day);
      availabilitiesWithTimes[date.day] = availabilitiesWithTimes[date.day] || {};
      availabilitiesWithTimes[date.day][availability.language] = date.start_time;
    }
  }

  dateAndTime.value = availabilitiesWithTimes;
  highlightedDates.value = Array.from(uniqueDays);
}

const handleDateSelection = (date: string, isWaitingList: boolean) => {
  selectedDate.value = new Date(date);
  isSelectedDateWaitingList.value = isWaitingList;
};

const { formatDate, formatDisplayDate } = useDates();

const chosenTime = (time: string, language: string) => {
  hasSelectedDateAndTime.value = Boolean(selectedDate.value && time);
  selectedTime.value = time;
  selectedLanguage.value = language;
}

// End Calendar

const handleBookNow = (event: MouseEvent) => {
  if (!selectedDate.value) return;

  const url = `/${localeStore.locale}/booking-step-1?id=${props.postId}&lang=${selectedLanguage.value}&date=${formatDate(selectedDate.value)}&start_time=${parseTimeslot(selectedTime.value)?.time}`;

  if (event.metaKey || event.ctrlKey) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
}

const handleBookWaitingList = (event: MouseEvent) => {
  if (!selectedDate.value) return;

  const url = `/${localeStore.locale}/waiting-list?id=${props.postId}&lang=${selectedLanguage.value}&date=${formatDate(selectedDate.value)}&start_time=${parseTimeslot(selectedTime.value)?.time}`;

  if (event.metaKey || event.ctrlKey) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
}
</script>

<style lang="scss" scoped>
.book-now, .book-now:active {
  font-size: 1.25rem;
  padding: 16px 32px;
  background-color: #00AB3C !important;
  border: none;

  &[disabled=true] {
    background-color: #00AB3C;
    cursor: default;
    opacity: 0.5;
  }

  &:hover {
    background-color: #008930 !important;
  }
}
</style>
